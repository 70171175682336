import React, { useContext, useState } from 'react';
import ReactGA from "react-ga4";
import RegionContext from '../../contexts/RegionContext';
import { __ } from '../../i18n';
import CancelIcon from '../../vectors/cancel.svg';
import logo from '../../vectors/logomin.svg';
import MenuIcon from '../../vectors/menu.svg';
import LangMenu from '../LangMenu';
import './style.scss';

const DefaultHeader = (props) => {
    const region = useContext(RegionContext);
    const { onSelectLang, hideDownload, hideLanguage, openAppProp } = props;
    const [menuOpen, setMenuOpen] = useState(false);

    const homeNav = (label) => {
        setMenuOpen(false);
        ReactGA.event({ category: 'nav', action: 'home-nav', label })
    };

    return (
        <>
            {
                !hideLanguage ?
                    (
                        <div id="topStrip" className="TopStrip">
                            <button className="MenuButton" onClick={() => setMenuOpen((v) => !v)}>
                                <img src={menuOpen ? CancelIcon : MenuIcon} className="MenuIcon" alt="logo" />
                            </button>
                            <LangMenu onSelect={onSelectLang} />
                        </div>
                    ) : null
            }
            <header>
                <img style={{ cursor: 'pointer' }} onClick={() => {
                    homeNav('home');
                    window.location.href = region.code === 'ro' ? '/ro' : '/en';
                }} src={logo} className="App-logo" alt="logo" />
                {
                    !hideDownload ?
                        (
                            <p className="DownloadControlsInHeader">
                                {region.code === 'ro' ?
                                    <>
                                        <a href="https://play.google.com/store/apps/details?id=app.onelearning.onekids"><img src="/images/download/playstore.png" alt="Playstore" /></a>
                                        <a href="https://apps.apple.com/us/app/one-learning-kids/id6443809900"><img src="/images/download/appstore.png?r=100" alt="App store" /></a>
                                    </> :
                                    <>
                                        <button><img src="/images/download/playstore_soon.png" alt="Playstore" /></button>
                                        <button><img src="/images/download/appstore_soon.png" alt="App store" /></button>
                                    </>
                                }
                            </p>
                        )
                        : null

                }
                <div className="MainMenu" style={menuOpen ? { display: 'block' } : {}}>
                    <ul>
                        <li><a onClick={() => homeNav('home')} href={region.code === 'ro' ? '/ro' : '/en'}>{__('Home')}</a></li>
                        <li><a onClick={() => homeNav('about')} href={region.code === 'ro' ? 'ro/desprenoi' : '/en/aboutus'}>{__('About')}</a></li>
                        {/* <li><a onClick={() => homeNav('meetsAGuide')} href={region.code === 'ro' ? '/ro#meetsAGuide' : '/en#meetsAGuide'}>{__('Benefits')}</a></li> */}
                        {/* <li><a onClick={() => homeNav('appContents')} href={region.code === 'ro' ? '/ro#appContents' : '/en#appContents'}>{__('Parenting App')}</a></li> */}
                        {/* <li><a onClick={() => homeNav('youth')} href="/youth">{__('For Organisations')}</a></li> */}
                        {/* <li><a onClick={() => homeNav('contact')} href={region.code === 'ro' ? '/ro#contact' : '/en#contact'}>{__('Contact')}</a></li> */}
                    </ul>
                    {
                        !hideDownload ?
                            (
                                <p className="DownloadControls">
                                    {region.code === 'ro' ?
                                        <>
                                            <a href="https://play.google.com/store/apps/details?id=app.onelearning.onekids"><img width="120px" src="/images/download/playstore.png" alt="Playstore" /></a>
                                            <a href="https://apps.apple.com/us/app/one-learning-kids/id6443809900"><img width="120px" src="/images/download/appstore.png?r=100" alt="App store" /></a>
                                        </> :
                                        <>
                                            <button><img width="120px" src="/images/download/playstore_soon.png" alt="Playstore" /></button>
                                            <button><img width="120px" src="/images/download/appstore_soon.png" alt="App store" /></button>
                                        </>
                                    }
                                </p>
                            )
                            : null
                    }
                  
                </div>
                <div className='space-placeholder'/>
                {openAppProp}
                {/* <div style={{ display: 'none' }} className="Actions">
                <button>{__('Donate')}</button>
                <button className="demoButton">{__('Book a demo')}</button>
                </div> */}
            </header>
        </>
    );
};

export default DefaultHeader;
