import axios from "axios";
import { useQuery } from "react-query";
import { appLinks, languageCode, queryKeys } from "../../app/constants";
import Header from "./header";

import { useEffect } from "react";
import CheckIcon from '../../images/common/green-check.svg';
import { useIsDesktopOrLaptop, useVerySmall } from "../Challenge/hooks/media";



const ShareView = ({ 
    playmomentId, 
    setPlaymomentLanguage, 
    translate,
}) => {


    const getPlaymomentDetailsAsync = async () => {
        try {

            const link = appLinks.playmomentDetails.replace('{id}', playmomentId)

            const parsedResponse = await axios.post(link)

            console.log('parsed response', parsedResponse)

            return parsedResponse?.data?.data

        } catch (error) {
            console.log('error getting playmoment details', error)
        }
    }


    const {
        data,
        isLoading,
        //refetch
    } = useQuery(
        [queryKeys.playmomentDetails, playmomentId],
        getPlaymomentDetailsAsync
    );

    const { isDesktopOrLaptop } = useIsDesktopOrLaptop()
    const { isVerySmall } = useVerySmall()


    const playmomentLangauge = data?.language ?? languageCode.en

    // const languageRef = useRef() 
    // useEffect(() => {
    //     languageRef.current = getCurrentLanguage()
    //     console.log('initial language', languageRef.current)
    //     return () => {
    //         changeLanguage(languageRef.current)
    //         console.log('cleanup initial language', languageRef.current)
    //     }

    // }, [])

    // useEffect(() => {
    //     const existingLanguage = languageRef.current
    //     //console.log('existing lanaguge', existingLanguage, languge)
    //     if (typeof languageCode[playmomentLangauge] !== undefined && existingLanguage !== playmomentLangauge) {
       
    //      //customi18n.changeLanguage(languge)
    //      changeLanguage(playmomentLangauge)
    //      const translate = customi18n.t("Learn and grow")
    //      //console.log('change language', languge, translate)
    //     }

    // }, [playmomentLangauge])

    useEffect(() => {
        setPlaymomentLanguage(playmomentLangauge)
    }, [playmomentLangauge])

    // const translate = useMemo(() => {

    //     const languageCaptions = AppLanguageCaptions?.[playmomentLangauge] ?? AppLanguageCaptions.en

    //     // can pass object with keys for faster/simpler access

    //     return (key) => languageCaptions?.[key] ?? key

    // }, [playmomentLangauge])

    const title = data?.title
    const likes = data?.likes
    const location = data?.location
    const duration = data?.duration
    const sparks = data?.sparks
    const oneValues = data?.oneValues
    const imageUrl = data?.imageUrl
    //const description = data?.description
    const intro = data?.intro
    const character = data?.character

    return (
        <div className={isDesktopOrLaptop ? "share-inner-container-row" : "share-inner-container-col"}>
            <div className="playmoment-image-container" >
                <div className="image-container"
                    style={{
                        // backgroundImage: `url("${imageUrl}")`,
                        // backgroundSize: 'contain',
                        // backgroundRepeat: 'no-repeat',
                        // backgroundPosition: isDesktopOrLaptop ? 'top' : 'bottom'
                    }}>
                    <img className="playmoment-image" src={imageUrl} />
                    <img className="character-image" src={`https://onelearning.app/images/characters/${character}.png`} />
                </div>
            </div>
            <div className="content-container">
                <Header
                    title={title}
                    likes={likes}
                    location={location}
                    duration={duration}
                    sparks={sparks}
                    oneValues={oneValues}
                />
                <div className="description-container">
                    <p className="description-text">{intro}</p>
                </div>
                <div className="download-title-container">
                    <h1 className="title">{translate('Download now to view this play moment...')}</h1>
                </div>
                <div className="download-actions">
                    <a href="https://play.google.com/store/apps/details?id=app.onelearning.onekids">
                        <img className={isVerySmall ? "download-image" : "download-image-small"} src="https://onelearning.app/images/download/playstore.png" alt="Playstore" />
                    </a>
                    <a href="https://apps.apple.com/us/app/one-learning-kids/id6443809900">
                        <img className={isVerySmall ? "download-image" : "download-image-small"} src="https://onelearning.app/images/download/appstore.png?r=100" alt="App store" />
                    </a>
                </div>
                <div className="points-container">
                    <ul>
                        <li className="point-item">
                            <img className='check-icon' src={CheckIcon} />
                            <span className="item-title">{translate('Spend quality family time')}</span>
                        </li>
                        <li className="point-item">
                            <img className='check-icon' src={CheckIcon} />
                            <span className="item-title">{translate('Learn and grow')}</span>
                        </li>
                        <li className="point-item">
                            <img className='check-icon' src={CheckIcon} />
                            <span className="item-title">{translate('Experience the magic of play')}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )

}

export default ShareView;